import { Stack, Typography } from '@mui/material';
import styles from './iframe-calc.module.scss';
import MuiSlider from '../mui/MuiSlider';
import MuiSliderDiscrete from '../mui/MuiSliderDiscrete';
import '../../styles/appDesktop.scss';
import '../../styles/appCommon.scss';
import useCalcCard from '../../hooks/useCalcCard';
import { annualRevenueOptions, offerPercentMarks, valMultipleMarks } from '../../calcConfig';
import {
    ANNUAL_REVENUE_INFO,
    OFFER_PERCENT_INFO,
    VALUATION_INFO,
    ANNUAL_REVENUE_TEXT,
    OFFER_PERCENT_TEXT,
    VAL_MULTIPLE_TEXT,
    TARGET_LABEL_HUBSPOT,
    VALUATION_LABEL_HUBSPOT
} from '../../textStrings';
import useInitialValues from '../../hooks/useInitialValues';
import { Tooltip, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SimpleSendToInbox from '../SimpleSendToInbox';

const IFrameCalc = () => {
    const initialValues = useInitialValues();

    const {
        annualRevenueOutput,
        offerPercentOutput,
        offerAmountOutput,
        valuationOutput,
        valMultipleLabel,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange
    } = useCalcCard(initialValues);
    return (
        <Stack className={styles.iframeContainer}>
            <Stack className={styles.calcSliders}>
                <Stack direction='column' className={styles.sliderContainer}>
                    <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                        <Typography variant='body1' className={styles.sliderLabel}>
                            {ANNUAL_REVENUE_TEXT}
                        </Typography>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        padding: '8px',
                                        backgroundColor: '#CCCCCC',
                                        color: '#0D0D0D',
                                        fontSize: 14,
                                        fontWeight: 400
                                    }
                                }
                            }}
                            title={
                                <Box className={styles.tooltip}>
                                    <ANNUAL_REVENUE_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', cursor: 'pointer', height: 16, width: 16 }} />
                        </Tooltip>
                    </Stack>
                    <MuiSlider
                        min={annualRevenueOptions.min}
                        max={annualRevenueOptions.max}
                        step={annualRevenueOptions.step}
                        defaultValue={initialValues.annualRevenue}
                        onValueChange={handleAnnualRevenueChange}
                        sx={{ width: '100%', boxSizing: 'border-box' }}
                    />
                    <Typography className={styles.sliderValue} variant='body1'>
                        {annualRevenueOutput}
                    </Typography>
                </Stack>
                <Stack direction='column' className={styles.sliderContainer}>
                    <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                        <Typography variant='body1' className={styles.sliderLabel}>
                            {OFFER_PERCENT_TEXT}
                        </Typography>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        padding: '8px',
                                        backgroundColor: '#CCCCCC',
                                        color: '#0D0D0D',
                                        fontSize: 14,
                                        fontWeight: 400
                                    }
                                }
                            }}
                            title={
                                <Box className={styles.tooltip}>
                                    <OFFER_PERCENT_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', cursor: 'pointer', height: 16, width: 16 }} />
                        </Tooltip>
                    </Stack>
                    <MuiSliderDiscrete
                        marks={offerPercentMarks}
                        min={offerPercentMarks[0].value}
                        max={offerPercentMarks.at(-1)!.value}
                        defaultValue={initialValues.offerPercent}
                        onValueChange={handleOfferPercentChange}
                        sx={{ width: '100%', boxSizing: 'border-box' }}
                    />
                    <Typography className={styles.sliderValue} variant='body1'>
                        {offerPercentOutput}
                    </Typography>
                </Stack>
                <Stack direction='column' className={styles.sliderContainer}>
                    <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
                        <Typography variant='body1' className={styles.sliderLabel}>
                            {VAL_MULTIPLE_TEXT}
                        </Typography>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        padding: '8px',
                                        backgroundColor: '#CCCCCC',
                                        color: '#0D0D0D',
                                        fontSize: 14,
                                        fontWeight: 400
                                    }
                                }
                            }}
                            title={
                                <Box className={styles.tooltip}>
                                    <VALUATION_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', cursor: 'pointer', height: 16, width: 16 }} />
                        </Tooltip>
                    </Stack>
                    <MuiSliderDiscrete
                        marks={valMultipleMarks}
                        defaultValue={initialValues.valMultiple}
                        min={valMultipleMarks[0].value}
                        max={valMultipleMarks.at(-1)!.value}
                        onValueChange={handleValMultipleChange}
                        sx={{ width: '100%', boxSizing: 'border-box' }}
                    />
                    <Typography className={styles.sliderValue} variant='body1'>
                        {valMultipleLabel}
                    </Typography>
                </Stack>
            </Stack>

            <Stack className={styles.valuationEmailContent}>
                <Typography variant='body1' className={styles.valuationTarget}>
                    {offerAmountOutput}
                </Typography>
                <Typography variant='body1' className={styles.valuationLabel}>
                    {TARGET_LABEL_HUBSPOT}
                </Typography>

                <Typography variant='body1' className={styles.valuationTarget}>
                    {valuationOutput}
                </Typography>
                <Typography variant='body1' className={styles.valuationLabel}>
                    {VALUATION_LABEL_HUBSPOT}
                </Typography>

                <SimpleSendToInbox
                    annualRevenue={annualRevenueOutput}
                    dropCalculatorLink={window.location.href}
                    offerPercent={offerPercentOutput}
                    valuationMultiple={valMultipleLabel}
                    fundingTarget={offerAmountOutput}
                    channelValuation={valuationOutput}
                    isHubspot={true}
                />
            </Stack>
        </Stack>
    );
};

export default IFrameCalc;
