import { FooterText } from "../mui/MuiText";
import { FOOTER_TEXT } from "../../textStrings";
import { isMobile } from 'react-device-detect'
import '../../styles/appMobile.scss';
import '../../styles/appDesktop.scss';

const dt = isMobile ? 'mb' : 'dsk';

function Footer() {
    return (
        <div className="footer-parent center">
            <FooterText className={`footer-solo-${dt}`}> 
                {FOOTER_TEXT}
            </FooterText>
        </div>
    );
}

export default Footer;
