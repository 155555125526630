import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import { TARGET_LABEL, TARGET_TITLE, VALUATION_LABEL, VALUATION_TITLE } from '../../textStrings';

function CalcInfo() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogTitle = {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '20px'
  }
  const dialogText = {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px'
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <InfoOutlinedIcon style={{ color: 'white' }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} PaperProps={{
        style: { backgroundColor: 'black', color: 'white' },
      }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{paddingTop: '30px'}}>
            <DialogTitle style={dialogTitle}> 
            <InfoOutlinedIcon style={{ marginRight: 8, color: 'white' }} />
                {TARGET_TITLE}
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={dialogText}>
                    {TARGET_LABEL}
                </DialogContentText>
            </DialogContent>
        </div>
        <div style={{paddingTop:'20px'}}>
            <DialogTitle style={dialogTitle}>
            <InfoOutlinedIcon style={{ marginRight: 8, color: 'white' }} />
                {VALUATION_TITLE}
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={dialogText}>
                    {VALUATION_LABEL}
                </DialogContentText>
            </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default CalcInfo;