import { useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { magenta400, white300 } from '../../../styles/styleConstants';

interface Mark {
    value: number;
    label: string;
}

interface MuiSliderDiscreteProps {
    marks: Mark[];
    min: number;
    max: number;
    defaultValue: number;
    onValueChange?: (value: number, label: string) => void;
    sx?: React.CSSProperties;
}

const StyledSlider = styled(Slider)({
    color: magenta400,
    height: 'var(--spacing-2, 8px)',
    width: '303px',
    margin: 0,
    '& .MuiSlider-track': {
        border: 'none'
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#FFFFFF',
        border: '4px solid currentColor',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: 'inherit'
        },
        '&:before': {
            display: 'none'
        }
    },
    '& .MuiSlider-rail': {
        backgroundColor: white300,
        opacity: 100
    },
    '& .MuiSlider-markLabel': {
        display: 'none',
        margin: 0
    },
    '& .MuiSlider-mark': {
        display: 'none'
    },
    '& .MuiSlider-root': {
        marginBottom: '0'
    }
});

const MuiSliderDiscrete = ({ marks, defaultValue, min, max, onValueChange, sx }: MuiSliderDiscreteProps) => {
    const [_value, setValue] = useState(defaultValue); // eslint-disable-line @typescript-eslint/no-unused-vars

    const handleChange = (_event: Event, newValue: number | number[]) => {
        const newValueNumber = newValue as number;
        setValue(newValueNumber);

        const selectedMark = marks.find(mark => mark.value === newValueNumber);

        if (onValueChange && selectedMark) {
            onValueChange(newValueNumber, selectedMark.label);
        }
    };

    return (
        <StyledSlider
            aria-label='MuiSliderDiscrete'
            onChange={handleChange}
            defaultValue={defaultValue}
            step={null}
            marks={marks}
            min={min}
            max={max}
            sx={sx}
        />
    );
};

export default MuiSliderDiscrete;
