export const HEADER_TITLE = 'Channel Drop Calculator';
export const HEADER_SUBTITLE_BASIC =
    'Answer 3 easy questions to see how much you could potentially raise on GigaStar Market.';
export const HEADER_SUBTITLE_ADV =
    'Answer 4 easy questions to see how much you could potentially raise on GigaStar Market.';

export const ANNUAL_REVENUE_TEXT = 'What was your last 12 months of revenue? Exclude brand deals.';
export const OFFER_PERCENT_TEXT = "How much of your channel's revenue would you be willing to sell to investors?";
export const VAL_MULTIPLE_TEXT =
    'Select a multiplier for your last 12 months of revenue, based on historical and reasonably expected future revenue.';
export const GOLD_PRICE = 'Desired Gold Price';

export const TARGET_TITLE = 'RAISE AMOUNT';
export const TARGET_LABEL = 'The projected amount GigaStar Market could help you raise.';
export const VALUATION_TITLE = 'VALUATION';
export const VALUATION_LABEL = 'Your annual YouTube channel revenue times the valuation multiple.';

export const TARGET_LABEL_HUBSPOT = 'Target to Raise';
export const VALUATION_LABEL_HUBSPOT = 'Channel Valuation';

export const AVAILABLE = 'Available';
export const PRICE = 'Price';
export const REVENUE_RIGHTS = 'Revenue Rights Per Investment';

export const SKEW_TITLE = 'Customize Token Count';
export const SKEW_GOLD = 'More Gold';
export const SKEW_DIAMOND = 'More Diamond';

export const FOOTER_TEXT =
    '© GigaStar 2024 | All Rights Reserved. This Website (The "Site") Is owned by Creator Networks, Inc. dba GigaStar, the parent company of GigaStar Portal, LLC dba GigaStar Market, Member of FINRA. We do not provide investment advice or make investment recommendations. Further, we do not provide tax, accounting, legal, or regulatory advice. By using our website, you are subject to our Privacy Policy. Read the Privacy Policy carefully before using this website. GigaStar Portal, LLC dba GigaStar Market, is an SEC-Registered Funding Portal and Member of FINRA, which offers investments to everyone over 18 years of age under Regulation Crowdfunding. Investment offerings are speculative, illiquid, and involve a high degree of risk, including the risk of loss of your entire investment. Past performance is not a guarantee of future results.';

export const ANNUAL_REVENUE_INFO = () => (
    <>
        <div className='tool-tip-title'>What revenue should I include here?</div>
        <div className='tool-tip-body'>
            You should only include revenue you receive from within the YouTube ecosystem – AdSense, subscriptions,
            Super Chats, etc.
            <br />
            <br />
            External brand deals or sponsorship revenue paid outside of YouTube should not be included.
        </div>
    </>
);

export const OFFER_PERCENT_INFO = () => (
    <>
        <div className='tool-tip-title'>How much future channel revenue should I sell?</div>
        <div className='tool-tip-body'>
            On GigaStar Market, YouTube channels can sell anywhere from 1%-50%, but no more than 50%, of their future
            YouTube revenue to Investors.
        </div>
    </>
);

export const VALUATION_INFO = () => (
    <>
        <div className='tool-tip-title'>What’s the impact of a multiplier?</div>
        <div className='tool-tip-body'>
            There are several factors that you may consider when selecting a multiplier, which may include how long your
            channel has been in business, your overall revenue growth, and the rate of your revenue growth. These, as
            well as other factors specific to your channel, may help inform how you select your multiplier.
            <br />
            <br />
            As an example, the higher the multiplier you assign your hypothetical offering, the higher the valuation you
            give to your channel’s potential future revenue, and the less future revenue you need to sell to achieve
            your maximum funding goal.
        </div>
    </>
);
