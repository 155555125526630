import { RecommendSearchParams, getRsuPriceConstraints, findSolution, ITierCounts, defaultRatio, enforceRange, groupByTier, applyRsuCountSkew, sortSolutions } from "./AlgoHelpers";
import { Solution } from "./Solution";

// Recommend a single solution in the search space, iterate if necessary by stepping away from the initial valuation
export function recommendSolutions(params: RecommendSearchParams): Solution[] {
    if (params.annualRevenue <= 0) throw Error(`annualRevenue must be positive`);
    if (params.multiple <= 0) throw Error(`multiple must be positive`);
    if (params.targetRsuPrice <= 0) throw Error(`targetRsuPrice must be positive`);
    if (params.offerPercent <= 0) throw Error(`Offer percent must be positive`);
    const minRsuPrice = 10; // This is more a lower bound than true min, Should be large enough to marginalize fees
    const targetRsuPrice = params.targetRsuPrice < minRsuPrice ? minRsuPrice : params.targetRsuPrice;
    const rsuPriceConstraints = getRsuPriceConstraints(targetRsuPrice);
    const solutions: Solution[] = [];
    // Scan for solutions by stepping valuation further away on each iteration
    const maxSteps = 20;
    for (let i = 0; i <= maxSteps; i += 1) {
        const s = findSolution(params, rsuPriceConstraints, i);
        if (s === null) continue;
        solutions.push(s);
        s.id = solutions.length;
        if (s.rsuPrice === targetRsuPrice) break; // cannot improve
    }
    sortSolutions(params, solutions);
    return solutions;
}

// Given a solution, output a scenario for each input ratio where each differs only by the token counts per tier.
export function distributeTokenCounts(
    solutions: Solution[], // Contains the rsuCount as an input and the (gold/plat/diam)Count as output
    rsuCountSkew: number = 0, // Used as a % where -100 is all gold, 0 is no effect on ratio, 100 is all diamond
    ratio: ITierCounts = defaultRatio, // The baseline for distribution by token count before applying the skew
): void {
    rsuCountSkew = enforceRange(rsuCountSkew, -100, 100);
    for (let i = 0; i < solutions.length; i += 1) {
        const solution = solutions[i];
        groupByTier(solution, ratio);
        applyRsuCountSkew(solution, rsuCountSkew);
    }
}
