import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const MuiTextEntryStyle = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
      borderRadius: '4px 4px 4px 4px',
    },
    '& label': {
      color: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF', 
    },
    '&.Mui-error': {
      '& fieldset': {
        borderColor: '#DC362E', 
      },
      '& .MuiFormHelperText-root': {
        color: '#CCCCCC', 
      }
    },
    '&:hover fieldset': {
      borderColor: '#00FAB9', 
    },
  },
  '& label': {
    color: '#FFFFFF !important',
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF', 
  },
  '& .MuiInputAdornment-root .MuiTypography-root': {
  color: '#FFFFFF',
},
});

interface MuiTextEntryProps {
  className?: string;
  defaultValue?: string;
  useError?: boolean;
  minRange?: number;
  maxRange?: number;
  errorText?: string;
  allowOnlyNumbers?: boolean;
  formatWithCommas?: boolean; //allowOnlyNumbers must be true for formatWithCommas to take effect.
  InputProps?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MuiTextEntry: React.FC<MuiTextEntryProps> = ({
  className = '',
  defaultValue = '',
  useError = false,
  minRange = -Infinity,
  maxRange = Infinity,
  errorText = '',
  allowOnlyNumbers = false,
  formatWithCommas = false,
  InputProps,
  onChange,
}) => {
  const [displayValue, setDisplayValue] = useState('');
  const [_actualValue, setActualValue] = useState(''); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);

  const formatNumberWithCommas = (value: string): string => {
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  useEffect(() => {
    let initialValue = defaultValue;

    if (allowOnlyNumbers) {
      initialValue = initialValue.replace(/[^\d]/g, '');
    }

    if (formatWithCommas && allowOnlyNumbers) {
      initialValue = formatNumberWithCommas(initialValue);
    }

    setDisplayValue(initialValue);
    setActualValue(initialValue.replace(/,/g, ''));
  }, [defaultValue, allowOnlyNumbers, formatWithCommas]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (allowOnlyNumbers) {
      newValue = newValue.replace(/[^\d]/g, '');
    }

    if (formatWithCommas && allowOnlyNumbers) {
      newValue = formatNumberWithCommas(newValue);
    }

    setDisplayValue(newValue);

    const newValueWithoutCommas = newValue.replace(/,/g, '');
    setActualValue(newValueWithoutCommas);

    if (useError) {
      const numericValue = parseInt(newValueWithoutCommas);
      setError(isNaN(numericValue) || numericValue < minRange || numericValue > maxRange);
    }

    if (onChange) {
      const modifiedEvent = {
        ...event,
        target: {
          ...event.target,
          value: newValueWithoutCommas,
        },
      };
      onChange(modifiedEvent);
    }
  };

  // Input props combined with native HTML input props
  const nativeInputProps = {
    ...(InputProps || {}),
    inputProps: {
      ...(InputProps?.inputProps || {}),
      inputMode: allowOnlyNumbers ? 'numeric' : undefined,
    },
  };

  return (
    <MuiTextEntryStyle
      className={className}
      type="text"
      error={error}
      helperText={error ? errorText : ''}
      value={displayValue}
      onChange={handleChange}
      InputProps={nativeInputProps}
      variant="outlined"
    />
  );
};

export default MuiTextEntry;