import { useLocation } from 'react-router-dom';
import BackgroundImg from '../../assets/background-main.webp';
import AdvancedCalcCardMobile from '../AdvancedCalcCardMobile';
import AdvancedCalcCardDesktop from '../AdvancedCalcCardDesktop';
import CalcCardDesktop from '../CalcCardDesktop';
import CalcCardMobile from '../CalcCardMobile';
import gigastarlogo from '../../assets/gigastarlogo.svg';
import { isMobile } from 'react-device-detect';
import '../../styles/appMobile.scss';
import '../../styles/appCommon.scss';
import '../../styles/appDesktop.scss';

const logoStyleDSK = {
    paddingTop: '24px',
    paddingBottom: '48px',
    minHeight: '35px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.60) 15.17%, rgba(0, 0, 0, 0.00) 100%)'
};

const logoStyleMB = {
    paddingTop: '16px',
    paddingBottom: '16px',
    minHeight: '35px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.60) 15.17%, rgba(0, 0, 0, 0.00) 100%)'
};

const logoStyle = isMobile ? logoStyleMB : logoStyleDSK;

const MainCalc = () => {
    const location = useLocation();

    const CalcCard = isMobile ? CalcCardMobile : CalcCardDesktop;
    const AdvancedCalcCard = isMobile ? AdvancedCalcCardMobile : AdvancedCalcCardDesktop;

    return (
        <div className='main-calc' style={{ backgroundImage: `url(${BackgroundImg})` }}>
            <div className={'gigastar-logo center'} style={logoStyle}>
                <a href='https://gigastar.io'>
                    <img src={gigastarlogo} alt='GigaStar Logo' />
                </a>
            </div>
            {location.pathname === '/' && (
                <div className={isMobile ? 'calc-card-mb center' : 'calc-card-dsk center'}>
                    <CalcCard />
                </div>
            )}
            {location.pathname === '/advanced' && (
                <div className={isMobile ? 'calc-card-mb center' : 'calc-card-dsk center'}>
                    <AdvancedCalcCard />
                </div>
            )}
        </div>
    );
};

export default MainCalc;
