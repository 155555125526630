import React, { ReactElement } from 'react';
import '../../styles/appCommon.scss';
import { styled } from '@mui/material/styles';

import { AccordionDetails, Divider as MuiDivider } from '@mui/material';
import { DetailText, TierText, OutputText } from '../mui/MuiText';
import { AVAILABLE, PRICE, REVENUE_RIGHTS } from '../../textStrings';
import { Solution } from '../../utils/Solution';

const IMG_SRC_GOLD = 'https://resources.gigastar.io/images/common/Gold_LG.webp';
const IMG_SRC_PLAT = 'https://resources.gigastar.io/images/common/Platinum_LG.webp';
const IMG_SRC_DIAM = 'https://resources.gigastar.io/images/common/Diamond_LG.webp';
const PERCENT_DECIMALS = 4;
const MULTIPLE_DECIMALS = 1; // Sufficient to show it's not an integer with the caveat "Approximately"

interface ScenarioDetailsProps {
    solution: Solution;
}

const AccordionContentDivider = styled(MuiDivider)({
    backgroundColor: '#595959',
    width: '1px',
    margin: '0'
});

const AccordionDetailsContent = styled(AccordionDetails)({
    padding: 0
});

function showAdjustedInputs(solution: Solution): ReactElement {
    if (solution == null || solution.multiple === solution.multipleAdjusted) return <div />;
    const roundedMultiple = solution.multipleAdjusted.toFixed(MULTIPLE_DECIMALS);
    return (
        <div>
            <br />
            <TierText align='center'>Actual Multiplier: ~ {roundedMultiple}x</TierText>
        </div>
    );
}

function showTotalTokens(solution: Solution): ReactElement {
    if (solution == null) return <div />;
    return (
        <div style={{ padding: '0 0 8px 0' }}>
            <br />
            <TierText align='center'>Total Tokens: {solution.tokenCount().toLocaleString()}</TierText>
        </div>
    );
}

const ScenarioDetails: React.FC<ScenarioDetailsProps> = ({ solution }) => {
    let goldCount = '--';
    let platCount = '--';
    let diamCount = '--';
    let goldPrice = '--';
    let platPrice = '--';
    let diamPrice = '--';
    let goldPercent = '--';
    let platPercent = '--';
    let diamPercent = '--';
    if (solution != null) {
        platCount = solution.platCount.toLocaleString();
        diamCount = solution.diamCount.toLocaleString();
        goldCount = solution.goldCount.toLocaleString();
        goldPrice = solution.goldPrice.toLocaleString();
        platPrice = solution.platPrice.toLocaleString();
        diamPrice = solution.diamPrice.toLocaleString();
        goldPercent = solution.goldPercent.toFixed(PERCENT_DECIMALS);
        platPercent = solution.platPercent.toFixed(PERCENT_DECIMALS);
        diamPercent = solution.diamPercent.toFixed(PERCENT_DECIMALS);
    }
    return (
        <div className='senario-details'>
            <AccordionDetailsContent>
                <div className='column-container'>
                    <div className='column-item'>
                        <div className='tier-div center'>
                            <img src={IMG_SRC_GOLD} alt='Gold Tier Icon' className='tier-icon' />
                            <TierText align='center'>
                                Gold
                                <br />
                                Tier
                            </TierText>
                        </div>
                        <div className='available-div'>
                            <DetailText align='center'>{AVAILABLE}</DetailText>
                            <OutputText align='center'>{goldCount}</OutputText>
                        </div>
                        <div className='price-div'>
                            <DetailText align='center'>{PRICE}</DetailText>
                            <OutputText align='center'>${goldPrice}</OutputText>
                        </div>
                        <div className='revenue-rights-div'>
                            <DetailText align='center'>{REVENUE_RIGHTS}</DetailText>
                            <OutputText align='center'>{goldPercent}%</OutputText>
                        </div>
                    </div>
                    <AccordionContentDivider orientation='vertical' flexItem />
                    <div className='column-item'>
                        <div className='tier-div center'>
                            <img src={IMG_SRC_PLAT} alt='Platinum Tier Icon' className='tier-icon' />
                            <TierText align='center'>
                                Platinum
                                <br />
                                Tier
                            </TierText>
                        </div>
                        <div className='available-div'>
                            <DetailText align='center'>{AVAILABLE}</DetailText>
                            <OutputText align='center'>{platCount}</OutputText>
                        </div>
                        <div className='price-div'>
                            <DetailText align='center'>{PRICE}</DetailText>
                            <OutputText align='center'>${platPrice}</OutputText>
                        </div>
                        <div className='revenue-rights-div'>
                            <DetailText align='center'>{REVENUE_RIGHTS}</DetailText>
                            <OutputText align='center'>{platPercent}%</OutputText>
                        </div>
                    </div>
                    <AccordionContentDivider orientation='vertical' flexItem />
                    <div className='column-item'>
                        <div className='tier-div center'>
                            <img src={IMG_SRC_DIAM} alt='Diamond Tier Icon' className='tier-icon' />
                            <TierText align='center'>
                                Diamond
                                <br />
                                Tier
                            </TierText>
                        </div>
                        <div className='available-div'>
                            <DetailText align='center'>{AVAILABLE}</DetailText>
                            <OutputText align='center'>{diamCount}</OutputText>
                        </div>
                        <div className='price-div'>
                            <DetailText align='center'>{PRICE}</DetailText>
                            <OutputText align='center'>${diamPrice}</OutputText>
                        </div>
                        <div className='revenue-rights-div'>
                            <DetailText align='center'>{REVENUE_RIGHTS}</DetailText>
                            <OutputText align='center'>{diamPercent}%</OutputText>
                        </div>
                    </div>
                </div>
            </AccordionDetailsContent>
            {showTotalTokens(solution)}
            {showAdjustedInputs(solution)}
        </div>
    );
};

export default ScenarioDetails;
