import React from 'react';
import ReactDOM from 'react-dom/client';
import { initGA4, logPageView } from './utils/analytics';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DropCalculator from './pages/DropCalculator';
import HubspotCalculator from './pages/HubspotCalculator';
import './index.css';

initGA4();
logPageView();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path='/' element={<DropCalculator />} />
                <Route path='/advanced' element={<DropCalculator />} />
                <Route path='/hubspot' element={<HubspotCalculator />} />
            </Routes>
        </Router>
    </React.StrictMode>
);
