export const annualRevenueOptions: IStepRange = {
    min: 30_000,
    max: 1_000_000,
    step: 10_000
};

export const offerPercentMarks: IValueLabel[] = [
    { value: 1, label: '1%' },
    { value: 5, label: '5%' },
    { value: 10, label: '10%' },
    { value: 15, label: '15%' },
    { value: 20, label: '20%' },
    { value: 25, label: '25%' },
    { value: 30, label: '30%' },
    { value: 35, label: '35%' },
    { value: 40, label: '40%' },
    { value: 45, label: '45%' },
    { value: 50, label: '50%' }
];

export const offerPercentDefault = 20;

export interface IValueLabel {
    value: number;
    label: string;
}

export const valMultipleMarks: IValueLabel[] = [
    {
        value: 1,
        label: '1x',
    },
    {
        value: 2,
        label: '2x',
    },
    {
        value: 3,
        label: '3x',
    },
    {
        value: 4,
        label: '4x',
    },
    {
        value: 5,
        label: '5x',
    },
    {
        value: 6,
        label: '6x',
    },
    {
        value: 7,
        label: '7x',
    },
    {
        value: 8,
        label: '8x',
    },
    {
        value: 9,
        label: '9x',
    },
    {
        value: 10,
        label: '10x',
    },
];

export interface IRange {
    min: number;
    max: number;
}

export interface IRangeConfig extends IRange {
    default: number;
}

export interface IStepRange extends IRange {
    step: number,
}

export interface IStepRangeConfig extends IRangeConfig {
    step: number,
}

export interface IValMultipleDefault extends IRange, IValueLabel {
}

export const valMultipleDefault: IValMultipleDefault =
{
    min: 1,
    max: 10,
    value: 5,
    label: '5x'
};

export const annualRevenueConfig: IRangeConfig =
{
    min: 10_000,
    max: 100_000_000,
    default: 100_000
};

export const offerPercentConfig: IRangeConfig =
{
    min: 1,
    max: 100,
    default: 10
};

export const goldPriceConfig: IRangeConfig =
{
    min: 10,
    max: 10_000,
    default: 40
};

export const skewConfig: IStepRangeConfig =
{
    min: -100,
    max: 100,
    default: 0,
    step: 10
};

export function valuationDefault(annualRevenue: number, valMultiple: number): number {
    return annualRevenue * valMultiple;
}

export function offerAmountDefault(annualRevenue: number, valMultiple: number, offerPercent: number) {
    return Math.floor(valuationDefault(annualRevenue, valMultiple) * offerPercent / 100);
}

export const rsuPriceMin = 10;

export const rsuMin = 100;