import { useState, useEffect } from 'react';
import { IInitialValues } from '../hooks/useInitialValues'
import { valuationDefault, offerAmountDefault } from '../calcConfig';
import { useNavigate } from 'react-router-dom';

interface UseCalcCardHook {
    annualRevenue: number;
    offerPercent: number;
    valMultiple: number;
    valuation: number | null;
    valMultipleLabel: string;
    offerAmount: number | null;
    annualRevenueOutput: string;
    offerPercentOutput: string;
    offerAmountOutput: string;
    valuationOutput: string;
    handleAnnualRevenueChange: (value: number) => void;
    handleOfferPercentChange: (value: number) => void;
    handleValMultipleChange: (value: number, label: string) => void;
}

function useCalcCard(initialValues: IInitialValues): UseCalcCardHook {
    const [annualRevenue, setAnnualRevenue] = useState<number>(initialValues.annualRevenue);
    const [offerPercent, setOfferPercent] = useState<number>(initialValues.offerPercent);
    const [valMultiple, setValMultiple] = useState<number>(initialValues.valMultiple);
    const [valMultipleLabel, setValMultipleLabel] = useState<string>(`${initialValues.valMultiple}x`);
    const [valuation, setValuation] = useState<number | null>(valuationDefault(initialValues.annualRevenue, initialValues.valMultiple));
    const [offerAmount, setOfferAmount] = useState<number | null>(offerAmountDefault(initialValues.annualRevenue, initialValues.valMultiple, initialValues.offerPercent));

    const navigate = useNavigate();

    useEffect(() => {
        const val = annualRevenue * valMultiple;
        setValuation(val);
        setOfferAmount(Math.floor(val * offerPercent / 100));
        const urlParams = new URLSearchParams({
            annualRevenue: annualRevenue.toString(),
            offerPercent: offerPercent.toString(),
            valMultiple: valMultiple.toString(),
        });
        navigate(`?${urlParams.toString()}`, { replace: true });
    }, [annualRevenue, offerPercent, valMultiple, navigate]);

    // Convert to formatted strings for display
    const annualRevenueOutput = `$${annualRevenue.toLocaleString()}`;
    const offerPercentOutput = `${offerPercent}%`;
    const offerAmountOutput = `$${offerAmount?.toLocaleString()}`;
    const valuationOutput = `$${valuation?.toLocaleString()}`;

    // Event handlers
    const handleAnnualRevenueChange = (value: number) => {
        setAnnualRevenue(value);
    };

    const handleOfferPercentChange = (value: number) => {
        setOfferPercent(value);
    };

    const handleValMultipleChange = (value: number, label: string) => {
        setValMultiple(value);
        setValMultipleLabel(label);
    };

    return {
        annualRevenue,
        offerPercent,
        valMultiple,
        valuation,
        valMultipleLabel,
        offerAmount,
        annualRevenueOutput,
        offerPercentOutput,
        offerAmountOutput,
        valuationOutput,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange
    };
}

export default useCalcCard;