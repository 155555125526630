import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { MuiTextEntry } from '../mui/MuiTextEntry';
import MuiSlider from '../mui/MuiSlider';
import InputAdornment from '@mui/material/InputAdornment';
import CalcInfo from '../CalcInfo';
import ScenarioDetails from '../ScenarioDetails';
import '../../textStrings';
import Disclaimer from '../Disclaimer';
import useCalcCardAdvanced from '../../hooks/useCalcCardAdvanced';
import { HeadlineTextMB, LabelTextMB, TitleTextMB, DisplayTextMB, SkewText, OutputText } from '../mui/MuiText';
import '../../styles/appCommon.scss';
import '../../styles/appMobile.scss';
import {
    valMultipleMarks,
    annualRevenueConfig,
    offerPercentConfig,
    goldPriceConfig,
    skewConfig
} from '../../calcConfig';
import {
    ANNUAL_REVENUE_TEXT,
    HEADER_SUBTITLE_ADV,
    HEADER_TITLE,
    OFFER_PERCENT_TEXT,
    TARGET_TITLE,
    VALUATION_TITLE,
    VAL_MULTIPLE_TEXT,
    GOLD_PRICE,
    SKEW_TITLE,
    SKEW_GOLD,
    SKEW_DIAMOND
} from '../../textStrings';
import MuiSelect from '../mui/MuiSelect';
import useInitialValues, { IInitialValues } from '../../hooks/useInitialValues';
import SimpleSendToInbox from '../SimpleSendToInbox';

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(13, 13, 13, 0.9)',
    borderColor: '#00FAB9',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'white',
    padding: 'var(--spacing-5, 32px) var(--spacing-3, 16px) var(--spacing-5, 24px) var(--spacing-3, 16px)',
    maxWidth: '85%',
    backdropFilter: 'blur(8px)',
    borderRadius: 'var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px)'
});

function CalcCardMobile() {
    const initialValues: IInitialValues = useInitialValues();

    const {
        annualRevenueOutput,
        offerPercentOutput,
        valMultipleLabel,
        offerAmountOutput,
        valuationOutput,
        solutions,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange,
        handleGoldPriceChange,
        handleSkewChange
    } = useCalcCardAdvanced(initialValues);

    return (
        <StyledCard className='styled-card-mb'>
            <div className='card-top-mb'>
                <div className='card-header-mb center'>
                    <HeadlineTextMB align='center' paddingBottom='10px'>
                        {HEADER_TITLE}
                    </HeadlineTextMB>
                    <LabelTextMB align='center'>{HEADER_SUBTITLE_ADV}</LabelTextMB>
                </div>
            </div>
            <div className='card-entries-mb'>
                <div className='card-entry-main center'>
                    <LabelTextMB align='center'>{ANNUAL_REVENUE_TEXT}</LabelTextMB>
                    <MuiTextEntry
                        className='card-text-entry-mb'
                        defaultValue={initialValues.annualRevenue.toLocaleString()}
                        useError={true}
                        minRange={annualRevenueConfig.min}
                        maxRange={annualRevenueConfig.max}
                        allowOnlyNumbers={true}
                        formatWithCommas={true}
                        errorText={`Must be between $${annualRevenueConfig.min.toLocaleString()} and $${annualRevenueConfig.max.toLocaleString()}`}
                        onChange={handleAnnualRevenueChange}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>
                        }}
                    />
                </div>
                <div className='card-entry-main center'>
                    <LabelTextMB align='center'>{OFFER_PERCENT_TEXT}</LabelTextMB>
                    <MuiTextEntry
                        className='card-text-entry-mb'
                        defaultValue={initialValues.offerPercent.toLocaleString()}
                        useError={true}
                        allowOnlyNumbers={true}
                        formatWithCommas={true}
                        minRange={offerPercentConfig.min}
                        maxRange={offerPercentConfig.max}
                        errorText={`Must be between ${offerPercentConfig.min.toLocaleString()}% and ${offerPercentConfig.max.toLocaleString()}%`}
                        onChange={handleOfferPercentChange}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                        }}
                    />
                </div>
                <div className='card-entry-main center'>
                    <LabelTextMB align='center'>{VAL_MULTIPLE_TEXT}</LabelTextMB>
                    <MuiSelect
                        onChange={handleValMultipleChange}
                        className='card-text-entry-mb'
                        options={valMultipleMarks}
                        defaultValue={initialValues.valMultiple}
                    />
                </div>
                <div className='card-entry-main center'>
                    <LabelTextMB align='center'>{GOLD_PRICE}</LabelTextMB>
                    <MuiTextEntry
                        className='card-text-entry-mb'
                        defaultValue={initialValues.goldPrice.toLocaleString()}
                        allowOnlyNumbers={true}
                        formatWithCommas={true}
                        useError={true}
                        minRange={goldPriceConfig.min}
                        maxRange={goldPriceConfig.max}
                        errorText={`Must be between ${goldPriceConfig.min.toLocaleString()} and ${goldPriceConfig.max.toLocaleString()}`}
                        onChange={handleGoldPriceChange}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>
                        }}
                    />
                </div>
            </div>
            <div className='card-output-advanced-mb'>
                <div className='card-output-child-mb'>
                    <TitleTextMB align='center' paddingBottom='8px'>
                        {TARGET_TITLE}
                    </TitleTextMB>
                    <DisplayTextMB align='center' paddingBottom='16px'>
                        {offerAmountOutput}
                    </DisplayTextMB>
                </div>
                <div className='card-output-child-mb'>
                    <TitleTextMB align='center' paddingBottom='8px'>
                        {VALUATION_TITLE}
                    </TitleTextMB>
                    <DisplayTextMB align='center' paddingBottom='16px'>
                        {valuationOutput}
                    </DisplayTextMB>
                </div>
            </div>
            <div className='calc-info-mb center'>
                <CalcInfo />
            </div>
            <div className='calc-rsu-skew-mb center'>
                <OutputText>{SKEW_TITLE}</OutputText>
                <MuiSlider
                    min={skewConfig.min}
                    max={skewConfig.max}
                    step={skewConfig.step}
                    defaultValue={0}
                    onValueChange={handleSkewChange}
                />
                <div className='calc-skew-text-mb'>
                    <SkewText align='left'>{SKEW_GOLD}</SkewText>
                    <SkewText align='right'>{SKEW_DIAMOND}</SkewText>
                </div>
            </div>
            <div className='calc-advanced-output-mb center'>
                <ScenarioDetails solution={solutions[0]} />
            </div>
            <div className='email-component'>
                <SimpleSendToInbox
                    annualRevenue={annualRevenueOutput}
                    dropCalculatorLink={window.location.href}
                    offerPercent={offerPercentOutput}
                    valuationMultiple={valMultipleLabel}
                    fundingTarget={offerAmountOutput}
                    channelValuation={valuationOutput}
                />
            </div>
            <div className='disclaimer-component center'>
                <Disclaimer />
            </div>
        </StyledCard>
    );
}

export default CalcCardMobile;
