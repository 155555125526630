import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const LargeHeadlineDSK = styled(Typography)({
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '64px',
    textTransform: 'uppercase'
});

export const LargeLabelDSK = styled(Typography)({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px'
});

export const AnnualRevenueLabelDSK = styled(Typography)(({}) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px'
}));

export const GoldLabelDSK = styled(Typography)(({}) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px'
}));

export const SliderText = styled(Typography)({
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '28px',
    textTransform: 'uppercase'
});

export const LargeTitleTextDSK = styled(Typography)({
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '28px',
    textTransform: 'uppercase'
});

export const LargeDisplayTextDSK = styled(Typography)({
    fontSize: '58px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '64px',
    textTransform: 'uppercase'
});

export const LargeBodyTextDSK = styled(Typography)({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px'
});

export const HeadlineTextMB = styled(Typography)({
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '32px',
    textTransform: 'uppercase'
});

export const LabelTextMB = styled(Typography)({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px'
});

export const TitleTextMB = styled(Typography)({
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    wordWrap: 'break-word'
});

export const DisplayTextMB = styled(Typography)({
    fontSize: '24px',
    fontWeight: '900',
    textTransform: 'uppercase',
    lineHeight: '20px',
    wordWrap: 'break-word'
});

export const FooterText = styled(Typography)({
    color: '#999999',
    fontSize: '.813rem',
    textAlign: 'justify'
});

export const DisclaimerText = styled(Typography)({
    color: 'white',
    fontSize: '.813rem',
    textAlign: 'justify'
});

export const ScenarioText = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    fontWeight: '900',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'uppercase'
});

export const TierText = styled(Typography)({
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    fontStyle: 'normal'
});

export const DetailText = styled(Typography)({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    fontStyle: 'normal',
    padding: '8px 0 0px 0'
});

export const OutputText = styled(Typography)({
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '24px',
    fontStyle: 'normal',
    padding: '4px 0 8px 0'
});

export const SkewText = styled(Typography)({
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'normal',
    fontStyle: 'normal'
});

export const ModalSubtitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    fontStyle: 'normal'
});
