import MainCalc from '../components/MainCalc';
import Button from '@mui/material/Button';
import '../styles/appCommon.scss';
import Footer from '../components/Footer';
import { isMobile } from 'react-device-detect';

const stickyStyle: React.CSSProperties = {
    display: 'flex',
    flexShrink: '0',
    width: '105%', //overflow to cutout edges
    height: '51px',
    position: 'fixed',
    padding: 'var(--spacing-3, 16px) var(--spacing-4, 24px)',
    gap: 'var(--spacing-2, 8px);',

    backgroundColor: '#00FAB9',
    color: '#0D0D0D',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'normal',

    //sticky style
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000
};

function DropCalculator() {
    return (
        <div style={{ backgroundColor: '#0D0D0D' }}>
            <MainCalc />
            <Footer />
            {isMobile && (
                <Button
                    sx={{
                        ...stickyStyle,
                        '&:hover': {
                            backgroundColor: '#8AFFE0'
                        }
                    }}
                    variant='contained'
                    color='primary'
                    href='https://apply.gigastarmarket.io/'
                >
                    Apply For a Drop
                </Button>
            )}
        </div>
    );
}

export default DropCalculator;
