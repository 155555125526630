import React, { useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)<FormControlProps>(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
      borderRadius: '4px 4px 4px 4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-error fieldset': {
      borderColor: '#DC362E',
    },
    '&:hover fieldset': {
      borderColor: '#00FAB9',
    },
    '&:hover .MuiSvgIcon-root': {
      color: '#00FAB9',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
  '& .MuiSvgIcon-root': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-icon': {
    color: '#FFFFFF',
  },
}));


const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
      borderRadius: '4px 4px 4px 4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-error fieldset': {
      borderColor: '#DC362E',
    },
    '&:hover fieldset': {
      borderColor: '#00FAB9',
    },
    '&:hover .MuiSvgIcon-root': {
      color: '#00FAB9',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
  '& .MuiSvgIcon-root': {
    color: '#FFFFFF', 
  },
  '& .MuiSelect-icon': {
    color: '#FFFFFF',
  },
});


interface MuiSelectProps {
    options: { value: number; label: string; }[];
    defaultValue?: number ;
    className?: string;
    onChange?: (event: SelectChangeEvent<unknown>) => void;
}

const MuiSelect: React.FC<MuiSelectProps> = ({ options, defaultValue, className, onChange }) => {
    const [value, setValue] = useState<number>(defaultValue ? defaultValue : 0);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const handleChange = (event: SelectChangeEvent<unknown>) => { 
        const newValue = parseInt(event.target.value as string, 10);
        setValue(newValue);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <StyledFormControl variant="outlined" fullWidth>
            <StyledSelect
                className={className}
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

export default MuiSelect;