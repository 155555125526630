import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const OutlineButton = styled(Button)({
    color: '#00FAB9',
    border: '1px solid #00FAB9',
    backgroundColor: 'var(--Background-primary, #0D0D0D)',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex',
    padding: 'var(--Spacing-3, 16px) var(--Spacing-4, 24px)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--Spacing-2, 8px)',
    '&:hover': {
        backgroundColor: 'var(--Background-primary, #0D0D0D)',
        color: '#8AFFE0',
        borderColor: '#8AFFE0',
        boxShadow: '8px 8px 12px 0px #000, -8px -8px 16px 0px #1A1A1A'
    }
});

export const SolidButton = styled(Button)({
    borderRadius: 8,
    border: '1px solid #00FAB9',
    background: '#00FAB9',
    textTransform: 'capitalize',
    width: '100%',

    color: '#000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex',
    padding: 'var(--Spacing-3, 16px) var(--Spacing-4, 24px)',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '#8AFFE0',
        borderColor: '#8AFFE0',
        boxShadow: '8px 8px 12px 0px #000, -8px -8px 16px 0px #1A1A1A'
    }
});
