import ReactGA from 'react-ga4';

export const initGA4 = (): void => {
    ReactGA.initialize('G-BN6NFL52SF');
};

export const logPageView = (): void => {
    ReactGA.send("pageview");
};

/**
 * Can be used to log a specific event
 * @param {string} action - The type of interaction (e.g., 'button_click').
 * @param {object} params - Additional parameters for the event.
 */
export const logEvent = (action: string, params: object = {}): void => {
    ReactGA.event(action, params);
};
