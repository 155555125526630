import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { annualRevenueConfig, offerPercentConfig, valMultipleDefault, goldPriceConfig, skewConfig, IRange, IRangeConfig } from '../calcConfig';

export interface IInitialValues {
    annualRevenue: number;
    offerPercent: number;
    valMultiple: number;
    goldPrice: number;
    skew: number;
}

function moveIntoRange(num: number, range: IRange): number | null {
    if (isNaN(num) || num == null) return null;
    if (num < range.min) return range.min;
    if (num > range.max) return range.max;
    return Math.round(num);
}

function getValue(input: number | null, config: IRangeConfig): number {
    return input ? input : config.default;
}

const useInitialValues = (): IInitialValues => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const urlAnnualRevenue = moveIntoRange(parseInt(queryParams.annualRevenue as string), annualRevenueConfig);
    const urlOfferPercent = moveIntoRange(parseInt(queryParams.offerPercent as string), offerPercentConfig);
    const urlValMultiple = moveIntoRange(parseInt(queryParams.valMultiple as string), valMultipleDefault);
    const urlGoldPrice = moveIntoRange(parseInt(queryParams.goldPrice as string), goldPriceConfig);
    const urlSkew = moveIntoRange(parseInt(queryParams.skew as string), skewConfig);

    const initialValues: IInitialValues = {
        annualRevenue: getValue(urlAnnualRevenue, annualRevenueConfig),
        offerPercent: getValue(urlOfferPercent, offerPercentConfig),
        valMultiple: urlValMultiple ? urlValMultiple : valMultipleDefault.value,
        goldPrice: getValue(urlGoldPrice, goldPriceConfig),
        skew: getValue(urlSkew, skewConfig),
    };

    return initialValues;
};

export default useInitialValues;
