import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { SolidButton } from '../mui/MuiButton';
import './email.scss';

interface EmailProps {
    annualRevenue: string;
    dropCalculatorLink: string;
    offerPercent: string;
    valuationMultiple: string;
    fundingTarget: string;
    channelValuation: string;
    isHubspot?: boolean;
}

const SimpleSendToInbox: React.FC<EmailProps> = ({
    annualRevenue,
    dropCalculatorLink,
    offerPercent,
    valuationMultiple,
    fundingTarget,
    channelValuation,
    isHubspot = false
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open) return;

        // Function to load jQuery
        const loadjQuery = () => {
            return new Promise<void>(resolve => {
                if (window.jQuery) {
                    resolve();
                } else {
                    const jqueryScript = document.createElement('script');
                    jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
                    jqueryScript.type = 'text/javascript';
                    jqueryScript.onload = () => resolve();
                    document.body.appendChild(jqueryScript);
                }
            });
        };

        // Load jQuery then HubSpot
        loadjQuery().then(() => {
            const script = document.createElement('script');
            script.src = 'https://js.hsforms.net/forms/embed/v2.js';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: import.meta.env.VITE_REACT_APP_HUBSPOT_PORTAL_ID,
                        formId: import.meta.env.VITE_REACT_APP_HUBSPOT_FORM_ID,
                        target: '#hubspotForm',
                        onFormReady: function ($form: any) {
                            $form.find('input[name="annual_revenue_input"]').val(annualRevenue).change();
                            $form.find('input[name="drop_calculator_link"]').val(dropCalculatorLink).change();
                            $form.find('input[name="offer_percent_input"]').val(offerPercent).change();
                            $form.find('input[name="valuation_multiple_input"]').val(valuationMultiple).change();
                            $form.find('input[name="funding_target_output"]').val(fundingTarget).change();
                            $form.find('input[name="channel_valuation_output"]').val(channelValuation).change();
                        }
                    });
                }
            };

            // Remove the script when the component unmounts
            return () => {
                document.body.removeChild(script);
            };
        });
    }, [open, annualRevenue, dropCalculatorLink, offerPercent, valuationMultiple, fundingTarget, channelValuation]);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <SolidButton variant='outlined' endIcon={<EmailIcon />} onClick={handleClickOpen}>
                Send To Inbox
            </SolidButton>
            <Dialog
                className='center'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: '#121212',
                        border: '1px solid #333',
                        borderRadius: '16px',
                        color: 'white',
                        minHeight: '388px',
                        minWidth: '45vw',
                        padding: '28px'
                    }
                }}
                sx={{ '.MuiBackdrop-root': { backgroundColor: isHubspot ? 'rgba(0,0,0,0)' : '' } }}
            >
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='dialog-content'>
                    <div id='hubspotForm'></div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SimpleSendToInbox;
