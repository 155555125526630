import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import MuiSlider from '../mui/MuiSlider';
import MuiSliderDiscrete from '../mui/MuiSliderDiscrete';
import CalcInfo from '../CalcInfo';
import '../../textStrings';
import useCalcCard from '../../hooks/useCalcCard';
import { HeadlineTextMB, LabelTextMB, SliderText, TitleTextMB, DisplayTextMB } from '../mui/MuiText';
import '../../styles/appCommon.scss';
import '../../styles/appMobile.scss';
import { annualRevenueOptions, valMultipleMarks, offerPercentMarks } from '../../calcConfig';
import {
    ANNUAL_REVENUE_INFO,
    OFFER_PERCENT_INFO,
    VALUATION_INFO,
    ANNUAL_REVENUE_TEXT,
    HEADER_SUBTITLE_BASIC,
    HEADER_TITLE,
    OFFER_PERCENT_TEXT,
    TARGET_TITLE,
    VALUATION_TITLE,
    VAL_MULTIPLE_TEXT
} from '../../textStrings';
import useInitialValues, { IInitialValues } from '../../hooks/useInitialValues';
import { Tooltip, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Disclaimer from '../Disclaimer';
import SimpleSendToInbox from '../SimpleSendToInbox';

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(13, 13, 13, 0.9)',
    borderColor: '#00FAB9',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'white',
    padding: 'var(--spacing-5, 32px) var(--spacing-2, 8px) var(--spacing-5, 24px) var(--spacing-2, 8px)',
    maxWidth: '85%',
    backdropFilter: 'blur(8px)',
    borderRadius: 'var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px)'
});

function CalcCardMobile() {
    const initialValues: IInitialValues = useInitialValues();

    const {
        annualRevenueOutput,
        offerPercentOutput,
        offerAmountOutput,
        valuationOutput,
        valMultipleLabel,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange
    } = useCalcCard(initialValues);

    return (
        <StyledCard className='styled-card-mb'>
            <div className='card-top-mb'>
                <div className='card-header-mb center'>
                    <HeadlineTextMB align='center' paddingBottom='10px'>
                        {HEADER_TITLE}
                    </HeadlineTextMB>
                    <LabelTextMB align='center'>{HEADER_SUBTITLE_BASIC}</LabelTextMB>
                </div>
            </div>
            <div className='card-sliders-mb'>
                <div className='card-slider-mb center'>
                    <LabelTextMB align='center'>{ANNUAL_REVENUE_TEXT}</LabelTextMB>
                    <MuiSlider
                        min={annualRevenueOptions.min}
                        max={annualRevenueOptions.max}
                        step={annualRevenueOptions.step}
                        defaultValue={initialValues.annualRevenue}
                        onValueChange={handleAnnualRevenueChange}
                    />
                    <SliderText align='center'>{annualRevenueOutput}</SliderText>
                    <Tooltip
                        title={
                            <Box>
                                <ANNUAL_REVENUE_INFO />
                            </Box>
                        }
                        arrow
                    >
                        <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                    </Tooltip>
                </div>
                <div className='card-slider-mb center'>
                    <LabelTextMB align='center'>{OFFER_PERCENT_TEXT}</LabelTextMB>
                    <MuiSliderDiscrete
                        marks={offerPercentMarks}
                        min={offerPercentMarks[0].value}
                        max={offerPercentMarks.at(-1)!.value}
                        defaultValue={initialValues.offerPercent}
                        onValueChange={handleOfferPercentChange}
                    />
                    <SliderText align='center'>{offerPercentOutput}</SliderText>
                    <Tooltip
                        title={
                            <Box>
                                <OFFER_PERCENT_INFO />
                            </Box>
                        }
                        arrow
                    >
                        <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                    </Tooltip>
                </div>
                <div className='card-slider-mb center'>
                    <LabelTextMB align='center'>{VAL_MULTIPLE_TEXT}</LabelTextMB>
                    <MuiSliderDiscrete
                        marks={valMultipleMarks}
                        defaultValue={initialValues.valMultiple}
                        min={valMultipleMarks[0].value}
                        max={valMultipleMarks.at(-1)!.value}
                        onValueChange={handleValMultipleChange}
                    />
                    <SliderText align='center'>{valMultipleLabel}</SliderText>
                    <Tooltip
                        title={
                            <Box>
                                <VALUATION_INFO />
                            </Box>
                        }
                        arrow
                    >
                        <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            </div>
            <div className='card-output-mb'>
                <div className='card-output-child-mb'>
                    <TitleTextMB align='center' paddingBottom='8px'>
                        {TARGET_TITLE}
                    </TitleTextMB>
                    <DisplayTextMB align='center' paddingBottom='16px'>
                        {offerAmountOutput}
                    </DisplayTextMB>
                </div>
                <div className='card-output-child-mb'>
                    <TitleTextMB align='center' paddingBottom='8px'>
                        {VALUATION_TITLE}
                    </TitleTextMB>
                    <DisplayTextMB align='center' paddingBottom='16px'>
                        {valuationOutput}
                    </DisplayTextMB>
                </div>
            </div>
            <div className='calc-info-mb center'>
                <CalcInfo />
            </div>
            <div className='email-component'>
                <SimpleSendToInbox
                    annualRevenue={annualRevenueOutput}
                    dropCalculatorLink={window.location.href}
                    offerPercent={offerPercentOutput}
                    valuationMultiple={valMultipleLabel}
                    fundingTarget={offerAmountOutput}
                    channelValuation={valuationOutput}
                />
            </div>
            <div className='disclaimer-component center'>
                <Disclaimer />
            </div>
        </StyledCard>
    );
}

export default CalcCardMobile;
