
import { rsuPriceMin } from "../calcConfig";
import { Solution, USD_DECIMAL_OFFSET, fitsPrecisionUsdc } from "./Solution";

export const RSU_GOLD = 1;
export const RSU_PLAT = 4;
export const RSU_DIAM = 16;
const RSU_PLAT_PER_DIAM = RSU_DIAM / RSU_PLAT;

export function sortAscend(a: number, b: number): number { return a - b; }
export function sortDescend(a: number, b: number): number { return b - a; }

// Round value up to the nearest increment of step
export function ceiling(value: number, step: number): number {
    if (step <= 0) return Math.ceil(value);
    return Math.ceil(value / step) * step;
}

// Round value down to the nearest increment of step
export function floorStep(value: number, step: number): number {
    if (step <= 0) return Math.floor(value);
    return Math.floor(value / step) * step;
}

export function fmt(a: number): string { return a.toLocaleString(); }

// Find all integers where the quotient can be divided without a remainder.
// Given 8 / 4 = 2 then 8 is the quotient and 4 is the divisor. Both 2 and 4 are factors as they can be swapped.
export function findFactors(
    quotient: number,
    factorMin: number,
    sortFunc: (a: number, b: number) => number = sortAscend
): number[] {
    if (quotient < 0) throw Error(`Invalid quotient: ${quotient}`);
    if (factorMin > quotient) throw Error(`Invalid factorMin: ${factorMin}`);
    const factors = new Set<number>();
    const max = Math.sqrt(quotient); // Math trick: No need to search higher
    for (let divisor = factorMin; divisor <= max; divisor += 1) {
        if (quotient % divisor === 0) {
            factors.add(divisor); // factor 1
            const factor2 = quotient / divisor;
            if (factorMin <= factor2) {
                factors.add(factor2); // Same as factor 1 for a perfect square but not duplicated
            }
        }
    }
    return Array.from(factors).sort(sortFunc);
}

export interface IRsuPriceConstraints {
    min: number, // Lower bound to exclude exotic solutions
    max: number, // Upper bound to exclude exotic solutions
    allowFraction: boolean, // true may yield more possible solutions
}

export function getRsuPriceConstraints(targetRsuPrice: number): IRsuPriceConstraints {
    return {
        min: Math.floor(rsuPriceMin),
        max: Math.ceil(Math.max(targetRsuPrice * 2, 100)), // arbitrary upper bound
        allowFraction: false,
    };
}

// Recommend solutions in ascending order by
export enum SolutionPriority {
    Price, // ...gold price distance from desired price input
    Multiple, // ...valuation multiple distance from multiple input
}

export class RecommendSearchParams {
    annualRevenue: number;
    multiple: number; // Valuation multiple
    offerPercent: number; // Percentage of the channel revenue share offered to investors (eg 10 = 10%)
    targetRsuPrice: number; // User input ideal gold price - may not have a good solution since given apriori
    priority: SolutionPriority;

    constructor(annualRevenue: number,
        multiple: number,
        offerPercent: number,
        targetRsuPrice: number,
        priority: SolutionPriority = SolutionPriority.Price,
    ) {
        this.annualRevenue = annualRevenue;
        this.multiple = multiple;
        this.offerPercent = offerPercent;
        this.targetRsuPrice = targetRsuPrice;
        this.priority = priority;
    }
}

export function findSolution(
    params: RecommendSearchParams,
    rsuPriceConstraints: IRsuPriceConstraints,
    valuationStepIndex: number = 0, // default is no step
    valuationStepSize: number = 1_000, // Has many factors to increase the chance of finding good solutions
): Solution | null {
    const { annualRevenue, multiple, offerPercent, targetRsuPrice } = params;
    const valuationIn = annualRevenue * multiple;
    const step = valuationStepIndex * valuationStepSize;
    const offerFraction = offerPercent / 100;
    const offerAmount = ceiling((valuationIn * offerFraction) + step, valuationStepSize);
    if (offerFraction <= 0) return null; // Should not happen
    const valuationAdjusted = offerAmount / offerFraction;
    const offerAmountSearchable = rsuPriceConstraints.allowFraction ? offerAmount * USD_DECIMAL_OFFSET : offerAmount;
    const rsuMin = 1;
    // console.trace(`findSolution: valuation: ${fmt(valuationIn)}, %: ${offerPercent}, valuationAdjusted: ${fmt(valuationAdjusted)}, valuationStepIndex: ${valuationStepIndex}, offerAmount: ${fmt(offerAmount)}`);
    const rsuCounts = findFactors(offerAmountSearchable, rsuMin, sortDescend);
    let minPriceDelta = Number.MAX_SAFE_INTEGER;
    let solution = null;

    for (let i = 0; i < rsuCounts.length; i += 1) {
        const rsuCount = rsuCounts[i];
        const rsuPrice = offerAmount / rsuCount; // evenly divisible based on findFactors
        // Apply filters
        if (rsuPrice < rsuPriceConstraints.min) {
            // console.trace(`findSolution: rsuPrice: ${rsuPrice} below range: [${rsuPriceConstraints.min}, ${rsuPriceConstraints.max}]`);
            continue; // prices are increasing
        }
        if (rsuPriceConstraints.max < rsuPrice) {
            // console.trace(`findSolution: rsuPrice: ${rsuPrice} above range: [${rsuPriceConstraints.min}, ${rsuPriceConstraints.max}]`);
            break; // price outside range and prices are increasing
        }
        const rsuFraction = rsuPrice / valuationAdjusted;
        if (!fitsPrecisionUsdc(rsuFraction)) {
            // console.trace(`findSolution: rsuPrice: ${fmt(rsuPrice)}, rsuFraction: ${rsuFraction} requires more precision than allowed`);
            continue; // Requires more precision than allowed
        }
        // Found a possible solution

        const priceDelta = Math.abs(targetRsuPrice - rsuPrice);
        if (priceDelta > minPriceDelta) {
            break; // optimization: Since rsuPrices are increasing, this and future iterations are further away
        }

        solution = new Solution(0, rsuCount, rsuFraction, rsuPrice, valuationIn, offerPercent, multiple);

        // Given the equation: valuation = (annualRevenue * multiple * offerFraction) with user inputs in parens,
        // the solution (if step > 0) is the result of 2 user inputs and 1 potentially adjusted input.
        solution.valuationAdjusted = Math.ceil(valuationAdjusted);
        solution.multipleAdjusted = valuationAdjusted / annualRevenue;

        const multipleTolerance = 0.5
        if (Math.abs(solution.multiple - solution.multipleAdjusted) > multipleTolerance) {
            // console.trace(`findSolution: multipleAdjusted: ${solution.multipleAdjusted} exceeds tolerance`);
            solution = null;
            continue; // multipleAdjusted exceeds tolerance
        }

        const offerPercentAdjusted = (valuationIn * offerFraction * 100) / valuationAdjusted;
        const offerPercentTolerance = 0.5
        if (Math.abs(solution.offerPercent - offerPercentAdjusted) > offerPercentTolerance) {
            // console.trace(`findSolution: offerPercentAdjusted: ${solution.offerPercentAdjusted} exceeds tolerance`);
            solution = null;
            continue; // offerPercentAdjusted exceeds tolerance
        }
        solution.setOfferAmount();
        // console.trace(`Possible solution: ${JSON.stringify(solution)}, valuationAdjusted: ${fmt(valuationAdjusted)}`);

        if (priceDelta === 0) break; // Cannot improve
        minPriceDelta = priceDelta;
    }
    return solution;
}

export function sortSolutions(params: RecommendSearchParams, solutions: Solution[]): void {
    switch (params.priority) {
        case SolutionPriority.Multiple:
            solutions.sort((a: Solution, b: Solution): number => {
                // sort by multiple distance ascending, targetRsuPrice distance ascending, rsuPrice descending
                const diffMultA = Math.abs(params.multiple - a.multiple);
                const diffMultB = Math.abs(params.multiple - b.multiple);
                if (diffMultA === diffMultB) {
                    const diffPriceA = Math.abs(params.targetRsuPrice - a.rsuPrice);
                    const diffPriceB = Math.abs(params.targetRsuPrice - b.rsuPrice);
                    if (diffPriceA === diffPriceB) {
                        return a.rsuPrice >= b.rsuPrice ? -1 : 1;
                    }
                    return diffPriceA - diffPriceB;
                }
                return diffMultA - diffMultB;
            });
            break;
        case SolutionPriority.Price: // fall through
        default:
            solutions.sort((a: Solution, b: Solution): number => {
                // sort by targetRsuPrice distance ascending, multiple distance ascending, rsuPrice descending
                const diffPriceA = Math.abs(params.targetRsuPrice - a.rsuPrice);
                const diffPriceB = Math.abs(params.targetRsuPrice - b.rsuPrice);
                if (diffPriceA === diffPriceB) {
                    const diffMultA = Math.abs(params.multiple - a.multiple);
                    const diffMultB = Math.abs(params.multiple - b.multiple);
                    if (diffMultA === diffMultB) return a.rsuPrice >= b.rsuPrice ? -1 : 1;
                    return diffMultA - diffMultB;
                }
                return diffPriceA - diffPriceB;
            });
            break;
    }
}

export interface ITierCounts {
    gold: number,
    plat: number,
    diam: number,
}

export function makeTierCounts(gold: number, plat: number, diam: number): ITierCounts {
    return { gold, plat, diam };
}

export const defaultRatio = makeTierCounts(8, 4, 3); // Token counts

// Convert RSUs to a distribution of gold, platinum, diamond tiers based on a desired ratio
export function groupByTier(
    s: Solution,
    ratio: ITierCounts,
): void {
    let { rsuCount } = s;

    // Batch process as much as possible for speed
    const batchSize = RSU_GOLD * ratio.gold + RSU_PLAT * ratio.plat + RSU_DIAM * ratio.diam;
    if (batchSize === 0) throw Error('Invalid batchSize: zero');
    const remainder = rsuCount % batchSize;
    const batchCount = (rsuCount - remainder) / batchSize;
    if (batchCount > 0) {
        if (ratio.gold > 0) s.goldCount = batchCount * ratio.gold;
        if (ratio.plat > 0) s.platCount = batchCount * ratio.plat;
        if (ratio.diam > 0) s.diamCount = batchCount * ratio.diam;
        rsuCount -= batchSize * batchCount;
    }

    // Process the remainder close to a ratio of 3:3:1 (gold/plat/diam)
    while (rsuCount > 0) { // aggregate RSUs into tiers from high to low 
        if (rsuCount >= RSU_DIAM) {
            s.diamCount += 1;
            rsuCount -= RSU_DIAM;
        }
        if (rsuCount >= RSU_PLAT) {
            const platCount = Math.min(RSU_PLAT_PER_DIAM - 1, rsuCount % RSU_PLAT);
            s.platCount += platCount;
            rsuCount -= platCount * RSU_PLAT;
        }
        const goldCount = Math.min(RSU_PLAT - 1, rsuCount);
        s.goldCount += goldCount;
        rsuCount -= goldCount;
    }
}

export function copyShallow<T>(obj: T): T { return { ...obj }; }
// function copyDeep<T>(obj: T): T { return JSON.parse(JSON.stringify(obj)); }

export function enforceRange(value: number, min: number, max: number): number {
    if (value < min) return min;
    return value > max ? max : value;
}

// Given a solution with an existing distribution, skew the token counts by a percent towards gold or diamond.
// * Skew: The % of RSUs to move from low tiers to high or vice versa
// * Bias: How much of the RSUs are shifted to/from gold vs platinum (a second-order bias)
export function applyRsuCountSkew(
    s: Solution, // Contains inputs of rsuCount and (gold/plat/diam)Count allocations
    skew: number, // Used as a % where -100 is all gold, 0 is no effect on ratio, 100 is all diamond
): Solution {
    if (skew === 0) return s;
    skew /= 100; // from percent

    const gdBias = 0.60;   // Bias from gold or diamond
    const platBias = 0.40; // intentionally not (1 - gdBias) as that leads to awkward epsilon issues
    if(gdBias + platBias !== 1.00) console.error("Biases must be kept in sync");
    if (skew < 0) { // Move RSUs to gold
        skew *= -1; // Normalize to positive
        if (skew == 1) {
            // Simplying complexities of an edge case
            s.goldCount = s.rsuCount
            s.platCount = 0
            s.diamCount = 0
            return s
        }
        // Calc tokens moved from high tiers to gold
        const srcRsusMax = floorStep((s.platCount * RSU_PLAT + s.diamCount * RSU_DIAM) * skew, RSU_PLAT);
        const srcRsusDiam = Math.min(s.diamCount * RSU_DIAM, floorStep(srcRsusMax * gdBias, RSU_DIAM));
        const srcRsusPlat = Math.min(s.platCount * RSU_PLAT, srcRsusMax - srcRsusDiam); // Handles no diam remaining
        const srcTokensPlat = srcRsusPlat / RSU_PLAT;
        const srcTokensDiam = srcRsusDiam / RSU_DIAM;

        // Update token counts
        s.goldCount += (srcTokensPlat * RSU_PLAT) + (srcTokensDiam * RSU_DIAM); // srcRsusMax may be greater
        s.platCount -= srcTokensPlat;
        s.diamCount -= srcTokensDiam;
    } else { // Move RSUs to diam
        // Calc tokens moved from low tiers to diamond
        const srcRsusMax = floorStep((s.goldCount + s.platCount * RSU_PLAT) * skew, RSU_PLAT);
        const srcRsusGold = floorStep(Math.min(s.goldCount, Math.floor(srcRsusMax * gdBias)), RSU_PLAT);
        let srcRsusPlat = Math.min(s.platCount * RSU_PLAT, srcRsusMax - srcRsusGold); // Handles no gold remaining
        const excessRsus = (srcRsusGold + srcRsusPlat) % RSU_DIAM;
        srcRsusPlat -= excessRsus;
        const srcTokensPlat = srcRsusPlat / RSU_PLAT;

        // Update token counts
        s.goldCount -= srcRsusGold;
        s.platCount -= srcTokensPlat;
        s.diamCount += (srcRsusGold + srcRsusPlat) / RSU_DIAM;
    }
    return s;
}
