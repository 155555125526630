import { useState, useEffect, useRef } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { annualRevenueConfig, offerPercentConfig, goldPriceConfig } from '../calcConfig';
import { distributeTokenCounts, recommendSolutions } from '../utils/Algos';
import { RecommendSearchParams, SolutionPriority } from '../utils/AlgoHelpers';
import { Solution, stringify } from '../utils/Solution';
import { IInitialValues } from '../hooks/useInitialValues'
import { useNavigate } from 'react-router-dom';

interface UseCalcCardHook {
    annualRevenueOutput: string;
    offerPercentOutput: string;
    valMultipleLabel: string;
    offerAmountOutput: string;
    valuationOutput: string;
    solutions: Solution[];
    handleAnnualRevenueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleOfferPercentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleValMultipleChange: (event: SelectChangeEvent<unknown>) => void;
    handleGoldPriceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSkewChange: (value: number) => void;
}

function isWithinRange(annualRevenue: number, offerPercent: number, goldPrice: number): boolean {
    return annualRevenueConfig.min <= annualRevenue && annualRevenue <= annualRevenueConfig.max &&
        offerPercentConfig.min <= offerPercent && offerPercent <= offerPercentConfig.max &&
        goldPriceConfig.min <= goldPrice && goldPrice <= goldPriceConfig.max;
}

// A contract between the advanced calculator and external applications that will parse the URL for i/o
export interface IQueryParams {
    annualRevenue: string;
    offerPercent: string;
    valMultiple: string;
    goldPrice: string;
    skew: string;
    solution: string;
}

function useCalcCardAdvanced(initialValues: IInitialValues): UseCalcCardHook {
    // User inputs
    const [annualRevenue, setAnnualRevenue] = useState<number>(initialValues.annualRevenue);
    const [offerPercent, setOfferPercent] = useState<number>(initialValues.offerPercent);
    const [valMultiple, setValMultiple] = useState<number>(initialValues.valMultiple);
    const [goldPrice, setGoldPrice] = useState<number>(initialValues.goldPrice);
    const [skew, setSkew] = useState<number>(initialValues.skew);

    // Derived from user inputs
    const [solutions, setSolutions] = useState<Solution[]>([]);
    const [prevSearchParamsStr] = useState<string>('');

    // Refs for immediate state tracking
    const annualRevenueRef = useRef(annualRevenue);
    const offerPercentRef = useRef(offerPercent);
    const valMultipleRef = useRef(valMultiple);
    const goldPriceRef = useRef(goldPrice);
    const skewRef = useRef(skew);
    const prevSearchParamsStrRef = useRef(prevSearchParamsStr);
    const navigate = useNavigate();

    const annualRevenueOutput = `$${annualRevenue.toLocaleString()}`;
    const offerPercentOutput = `${offerPercent}%`;
    const valMultipleLabel = `${valMultiple}x`;

    useEffect(() => {
        // Update state from user input
        annualRevenueRef.current = annualRevenue;
        offerPercentRef.current = offerPercent;
        valMultipleRef.current = valMultiple;
        goldPriceRef.current = goldPrice;
        skewRef.current = skew;

        // Avoid redundant searches - could memoize this
        const searchParams = new RecommendSearchParams(annualRevenueRef.current, valMultipleRef.current,
            offerPercentRef.current, goldPriceRef.current, SolutionPriority.Price);
        const searchParamsStr = JSON.stringify({ searchParams, skew });
        if (searchParamsStr === prevSearchParamsStrRef.current) {
            return; // same as previous search
        }
        prevSearchParamsStrRef.current = searchParamsStr;

        // Skip invalid searches
        if (!isWithinRange(annualRevenueRef.current, offerPercentRef.current, goldPriceRef.current)) {
            if (prevSearchParamsStrRef.current.length === 0) return;
            prevSearchParamsStrRef.current = '';
            setSolutions([]);
            return;
        }

        // Find solutions - only using 1 now but could present user with alternatives
        const newSolutions = recommendSolutions(searchParams);
        setSolutions(newSolutions);
        if (newSolutions.length == 0) {
            return;
        }
        distributeTokenCounts(newSolutions, skewRef.current);

        // Update URL query parameters
        const queryParams: IQueryParams = {
            annualRevenue: annualRevenueRef.current.toString(),
            offerPercent: offerPercentRef.current.toString(),
            valMultiple: valMultipleRef.current.toString(),
            goldPrice: goldPriceRef.current.toString(),
            skew: skewRef.current.toString(),
            solution: stringify(newSolutions[0]),
        };
        const urlParams = new URLSearchParams({ ...queryParams });
        navigate(`?${urlParams.toString()}`, { replace: true });
    }, [annualRevenue, offerPercent, valMultiple, goldPrice, skew, navigate]);

    const stringToInt = (s: string): number => {
        const n = parseInt(s);
        return isNaN(n) ? 0 : n;
    }

    const handleAnnualRevenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnnualRevenue(stringToInt(event.target.value));
    };

    const handleOfferPercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOfferPercent(stringToInt(event.target.value));
    };

    const handleValMultipleChange = (event: SelectChangeEvent<unknown>) => {
        setValMultiple(stringToInt(event.target.value as string));
    }

    const handleGoldPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGoldPrice(stringToInt(event.target.value));
    };

    const handleSkewChange = (value: number) => {
        setSkew(value);
    };

    // Convert to formatted strings for display
    let offerAmountOutput = '--';
    let valuationOutput = '--';
    if (solutions.length > 0) {
        // The solution often adjusts the valuation so we do not use 'offerAmount' here as that's based on user input
        // (ie the ideal), instead we show output based on the solution, same for the valuation. The important thing
        // is that the annualRevenue and offerPercent are fixed, following values are based on an adjusted multiple
        const s = solutions[0];
        offerAmountOutput = `$${s.offerAmount.toLocaleString()}`;
        valuationOutput = `$${s.valuationAdjusted.toLocaleString()}`;
    }

    return {
        annualRevenueOutput,
        offerPercentOutput,
        valMultipleLabel,
        offerAmountOutput,
        valuationOutput,
        solutions,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange,
        handleGoldPriceChange,
        handleSkewChange
    };
}

export default useCalcCardAdvanced;
