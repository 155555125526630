export const RSU_GOLD = 1;
export const RSU_PLAT = 4;
export const RSU_DIAM = 16;

export const USD_DECIMALS = 2; // Allows prices with pennies to be considered
export const USD_DECIMAL_OFFSET = Math.pow(10, USD_DECIMALS);

export const USDC_DECIMALS = 6; // Decimals represented on-chain, eg 0.0001% would be the min increment
export const USDC_DECIMAL_OFFSET = Math.pow(10, USDC_DECIMALS);

export const PERCENT_DECIMALS = USDC_DECIMALS - 2; // A % has 2 decimals implied

export function fitsPrecisionUsd(value: number): boolean {
    const a = value * USD_DECIMAL_OFFSET;
    const b = Math.floor(a);
    const delta = Math.abs(a - b);
    // if (delta > Number.EPSILON) console.log(`fitsPrecisionUsdc: delta: ${delta.toFixed(20)}`);
    return delta < Number.EPSILON; // cannot do a == b due to tiny variations
}

export function fitsPrecisionUsdc(value: number): boolean {
    const a = value * USDC_DECIMAL_OFFSET;
    const b = Math.floor(a);
    const delta = Math.abs(a - b);
    // if (delta > Number.EPSILON) console.log(`fitsPrecisionUsdc: delta: ${delta.toFixed(20)}`);
    return delta < Number.EPSILON; // cannot do a == b due to tiny variations
}

export function requirePrecisionUsd(value: number, name: string): number {
    if (fitsPrecisionUsd(value)) return value;
    throw Error(`${name} exceeds ${USD_DECIMALS} decimals, value: ${value.toFixed(USD_DECIMALS + 1)}`);
}

export function requirePrecisionUsdc(value: number, name: string): number {
    if (fitsPrecisionUsdc(value)) return value;
    throw Error(`${name} exceeds ${USDC_DECIMALS} decimals, value: ${value.toFixed(USDC_DECIMALS + 1)}`);
}

export function requireInteger(value: number, name: string): number {
    if (Number.isInteger(value)) return value;
    throw Error(`${name} is not an integer, value: ${value.toFixed(16)}`);
}

export function validateSolution(s: Solution): Solution {
    try {
        requireInteger(s.goldCount, 'goldCount');
        requireInteger(s.platCount, 'platCount');
        requireInteger(s.diamCount, 'diamCount');
        const rsuCount = s.goldCount * RSU_GOLD + s.platCount * RSU_PLAT + s.diamCount * RSU_DIAM;
        if (rsuCount !== s.rsuCount) throw Error(`rsuCount: expected(${s.rsuCount}), actual(${rsuCount})`);
        return s;
    } catch (e) {
        const err = e as Error;
        throw Error(`Solution(${s.id}): ${err.message}, solution: ${JSON.stringify(s)}`);
    }
}

// Percent and Price for each tier can be implied from the RSU/gold value
export interface ISolutionOutput {
    goldCount: number;
    platCount: number;
    diamCount: number;

    goldPercent: number;
    platPercent: number;
    diamPercent: number;

    goldPrice: number;
    platPrice: number;
    diamPrice: number;

    valuationAdjusted: number;
    offerAmount: number;
    multipleAdjusted: number;
}
export function stringify(o: ISolutionOutput): string {
    return `{ goldCount: ${o.goldCount}, platCount: ${o.platCount}, diamCount: ${o.diamCount}, goldPercent: ${o.goldPercent}, platPercent: ${o.platPercent}, diamPercent: ${o.diamPercent}, goldPrice: ${o.goldPrice}, platPrice: ${o.platPrice}, diamPrice: ${o.diamPrice}, valuationAdjusted: ${o.valuationAdjusted}, offerAmount: ${o.offerAmount}, multipleAdjusted: ${o.multipleAdjusted} }`;
}

export class Solution implements ISolutionOutput {
    id: number;

    // Per tier values are derived from RSU values
    rsuCount: number;
    rsuFraction: number;
    rsuPercent: number;
    rsuPrice: number;

    valuation: number;
    offerPercent: number;
    multiple: number;

    goldCount: number;
    platCount: number;
    diamCount: number;

    goldPercent: number;
    platPercent: number;
    diamPercent: number;

    goldPrice: number;
    platPrice: number;
    diamPrice: number;

    valuationAdjusted: number;
    multipleAdjusted: number;
    offerAmount!: number;

    constructor(
        id: number,
        rsuCount: number,
        rsuFraction: number,
        rsuPrice: number,
        valuation = 0,
        offerPercent = 0,
        multiple = 0,
        goldCount = 0,
        platCount = 0,
        diamCount = 0,
    ) {
        this.id = id;
        if (rsuCount <= 0) throw Error(`rsuCount must be positive: ${rsuCount}`);
        if (rsuFraction <= 0 || 1 < rsuFraction) throw Error(`rsuFraction out-of-range (0,1]: ${rsuFraction}`);
        if (rsuPrice <= 0) throw Error(`rsuPrice must be positive: ${rsuPrice}`);
        if (goldCount < 0) throw Error(`goldCount cannot be negative: ${goldCount}`);
        if (platCount < 0) throw Error(`platCount cannot be negative: ${platCount}`);
        if (diamCount < 0) throw Error(`diamCount cannot be negative: ${diamCount}`);
        this.rsuCount = requireInteger(rsuCount, 'rsuCount');
        this.rsuFraction = requirePrecisionUsdc(rsuFraction, 'rsuFraction');
        this.rsuPercent = rsuFraction * 100;
        this.rsuPrice = requirePrecisionUsd(rsuPrice, 'rsuPrice');
        this.goldCount = goldCount;
        this.platCount = platCount;
        this.diamCount = diamCount;
        this.goldPercent = this.rsuPercent * RSU_GOLD;
        this.platPercent = this.rsuPercent * RSU_PLAT;
        this.diamPercent = this.rsuPercent * RSU_DIAM;
        this.goldPrice = this.rsuPrice * RSU_GOLD;
        this.platPrice = this.rsuPrice * RSU_PLAT;
        this.diamPrice = this.rsuPrice * RSU_DIAM;
        if (valuation < 0) throw Error(`valuation cannot be negative: ${valuation}`);
        if (offerPercent < 0) throw Error(`offerPercent cannot be negative: ${offerPercent}`);
        if (multiple < 0) throw Error(`multiple cannot be negative: ${multiple}`);
        this.valuation = valuation;
        this.offerPercent = requirePrecisionUsdc(offerPercent, 'offerPercent');
        this.multiple = multiple; // No precision check - may require infinite
        this.valuationAdjusted = this.valuation;
        this.multipleAdjusted = this.multiple;
        this.setOfferAmount();
    }

    setOfferAmount() {
        this.offerAmount = Math.floor(this.valuationAdjusted * this.offerPercent / 100);
    }

    tokenCount(): number { return getTokenCount(this) }
}

export function getTokenCount(s: Solution): number { return s.goldCount + s.platCount + s.diamCount; }
