import { useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { magenta400, white300 } from '../../../styles/styleConstants';

interface MuiSliderProps {
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    onValueChange?: (value: number) => void;
    sx?: React.CSSProperties;
}

const StyledSlider = styled(Slider)({
    color: magenta400,
    height: 'var(--spacing-2, 8px)',
    width: '303px',
    '& .MuiSlider-track': {
        border: 'none'
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#FFFFFF',
        border: '4px solid currentColor',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: 'inherit'
        },
        '&:before': {
            display: 'none'
        }
    },
    '& .MuiSlider-rail': {
        backgroundColor: white300,
        opacity: 100
    }
});

const MuiSlider = ({ min, max, step, defaultValue, onValueChange, sx }: MuiSliderProps) => {
    const [_value, setValue] = useState(defaultValue); // eslint-disable-line @typescript-eslint/no-unused-vars

    const handleChange = (_event: Event, newValue: number | number[]) => {
        const newValueAsNumber = newValue as number;
        setValue(newValueAsNumber);
        if (onValueChange) {
            onValueChange(newValueAsNumber);
        }
    };

    return (
        <StyledSlider
            aria-label='MuiSlider'
            onChange={handleChange}
            defaultValue={defaultValue}
            step={step}
            min={min}
            max={max}
            sx={sx}
        />
    );
};

export default MuiSlider;
