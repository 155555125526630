import { DisclaimerText } from "../mui/MuiText";
import { isMobile } from 'react-device-detect'
import '../../styles/appMobile.scss';
import '../../styles/appDesktop.scss';

const dt = isMobile ? 'mb' : 'dsk';

function Disclaimer() {
    return (
        <div className={`disclaimer-parent-${dt} center`}>
            <DisclaimerText className={`disclaimer-${dt}`}> 
                This Channel Drop Calculator uses the following inputs to arrive at a hypothetical “Valuation”:
                The last 12 months of channel revenue (excluding brand deals)
                is multiplied by the selected valuation multiplier.
                The hypothetical “Raise Amount” is arrived at by multiplying the "Valuation" by the percentage of channel revenue the channel owner is willing to sell.
                <br/>
                <br/>
                This Channel Value Calculator is hypothetical in nature and does not consider all
                factors that may be used to determine a valuation, does not reflect an actual valuation,
                and is not a guarantee of future results, outcomes, or performance.
                Past performance is not indicative or a guarantee of future results.
                Additionally, this calculator is intended for illustrative purposes for YouTube Channel Creators to use at their own risk and discretion.
                This Channel Value Calculator and the information contained herein are not to be construed as advice or a
                recommendation related to the valuation or performance of any channel, company, or securities offering.
                <br/>
                <br/>
                Investment offerings are speculative, illiquid, and involve a high degree of risk,
                including the risk of loss of your entire investment. Past performance is not a guarantee of future results.
            </DisclaimerText>
        </div>
    );
}

export default Disclaimer;
